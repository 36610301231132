$(document).ready(function () {
    // scroll 80% show popup modal 1 time
    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    // set cookie
    function setCookie(name, value, options, path) {

        options = options || {};

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = name + "=" + value;

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        if (path) {
            updatedCookie += "; path=" + path;
        }
        document.cookie = updatedCookie;
    }
    if (getCookie('popup') != 'false') {
        setCookie('popup', 'true', 1);
    }
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var height = $(window).height();
        var docHeight = $(document).height();
        var scrollPercent = (scroll / (docHeight - height)) * 100;
        if (scrollPercent > 80) {
            if (getCookie('popup') == 'true') {
                $('#popupContactModal').modal('show');
                $('#popupContactModal').find('input').first().focus();
                setCookie('popup', 'false', 1);
            } else {
                history.replaceState("", "", location.pathname)
            }
        }

    });

    setTimeout(function () {
        setCookie('popup', 'true', 1);
    }, 86400000);

    $('.close-modal-btn').click(function (e) {
        e.preventDefault();
        $('#popupContactModal').modal('hide');
        setCookie('popup', 'false', 1);
        history.replaceState("", "", location.pathname);
    });
});